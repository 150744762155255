<template>
    <main>
        <title-row :title="$t('productConfiguration')">
        </title-row>
    </main>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import TitleRow from "../../components/layout/TitleRow.vue";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";

export default defineComponent({
    components: {
        TitleRow,
    },
    setup() {
        const store = useStore();
        /**
         *  State
         */
        const state = reactive({
            filters: {
                text: "",
            },
        });

        /**
         *  Functions
         */
        function SET_FILTER_ACTIVE_ROUTE() {
            store.commit(
                `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_FILTER_ACTIVE_ROUTE}`
            );
        }
        return {
            state,
            SET_FILTER_ACTIVE_ROUTE,
        };
    },
});
</script>
