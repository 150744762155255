<template>
    <div
        class="filter w-full flex flex-row justify-start -mt-1 items-center mb-2"
        v-if="GET_FILTER_ACTIVE_ROUTE == $route.name"
    >
        <slot name="items"></slot>
    </div>
    <h2 class="pb-5 mt-1 text-2xl font-bold h-11 text-white-dark" v-else>{{ title }}</h2>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";

export default defineComponent({
    name: "TitleRow",
    components: {},

    props: {
        title: {
            type: String,
            required: true,
        },
    },
    setup() {
        const store = useStore();
        /**
         *  Computed
         */
        const GET_FILTER_ACTIVE_ROUTE = computed(
            () =>
                store.getters[
                    `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_FILTER_ACTIVE_ROUTE}`
                ]
        );
        return {
            GET_FILTER_ACTIVE_ROUTE,
        };
    },
});
</script>
<style lang="scss"></style>
